body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: "Montserrat", sans-serif;
} */

::placeholder {
  color: #002c99 !important;
  opacity: 1;
}

label {
  color: #002c99 !important;
  font-family: "Montserrat" !important;
  font-weight: 400 !important;
}
/* 
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  top: -6px !important;
  color: #002c99 !important;
  font-weight: 400 !important;
  font-size: 15px !important;
  font-family: "Montserrat" !important;
} */

@font-face {
  font-family: karbon;
  src: url(../src/font/FontsFree-Net-KarbonSlabStencil-Regular.ttf);
}
