* {
  margin: 0;
  padding: 0;
}
.login-modal {
  max-width: 320px !important;
}

.line {
  margin-left: 4%;
}

.dropdown {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  position: absolute;
  box-shadow: 0px 5px 10px gray;
  z-index: 9999;
  width: 200px;
  padding: 2%;
  margin-top: 6%;
  list-style: none;
  background-color: #eff3fa;
  border-radius: 0.1rem;
  line-height: 2;
  display: none;
}

.dropdown.show {
  display: block;
}

.dropdown.dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
  font-size: 14px;
  width: 250px;
  font-family: "Montserrat", sans-serif;
  position: absolute;
  box-shadow: none;
  background-color: #eff3fa;
  line-height: 1.8;
  box-shadow: 0px 5px 10px gray;
}

@media (max-width: 600px) {
  .dropdown {
    box-shadow: none;
    background: none;
    line-height: 1.6;
    margin-top: 0%;
    margin-left: 2%;
  }
  .line {
    margin-left: 2%;
  }
  .dropdown.dropdown-submenu {
    width: 70%;
    margin-left: -4%;
  }
}

.date {
  border-radius: 20px;
  font-family: "Montserrat", sans-serif;
}

.day {
  font-family: "Montserrat" !important;
  padding-bottom: 2%;
}

#edu-input.is-valid {
  border-color: #2097f5;
  box-shadow: #2097f5 0px 1px 6px;
  border-right: 0.5px solid #2097f5;
  background-size: 25px;
  background-image: url("./asserts/tick.png");
}

#edu-input.is-valid + #dropdown-id {
  border-color: #2097f5;
  border-left: 0.5px solid #2097f5;
  box-shadow: #2097f5 0px 1px 6px;
}

.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  font-family: "Montserrat";
  font-size: 15px;
  color: #002c99;
}
